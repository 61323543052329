<template>
  <div class="info">
    <div class="jobshwheader"></div>
    <div class="min_body">
      <div class="job_describe">
        <!-- 职位要求及待遇岗位 -->
        <div class="job_describe_top">
          <div class="jjxx">行政审批单信息</div>
        </div>
        <div class="sj_job_box mt10"></div>
        <div class="job_describe_cengter">
          <!--          <div class="job_describe_cengter_header">{{ info.Shop_Name }}</div>-->

          <div class="user_undergo_box">
            <div class="user_undergo user_undergo_a">
              发送人员：{{ info.Staff_Name }}
            </div>
            <div class="user_undergo user_undergo_a">
              单&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;位：{{ info.Unit }}
            </div>
            <div class="user_undergo user_undergo_a">
              地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;点：{{ info.Address }}
            </div>
            <div class="user_undergo user_undergo_a">
              联系人员：{{ info.LinkMan }}
            </div>
            <div class="user_undergo user_undergo_a">
              联系电话：{{ info.TEL }}
            </div>
            <div class="user_undergo user_undergo_a">
              所属街道：{{ info.Street }}
            </div>
            <div class="user_undergo user_undergo_a">
              占地面积：{{ info.Area }}
            </div>
            <div class="user_undergo user_undergo_a">
              类&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别：{{ info.Type }}
            </div>
            <div class="user_undergo user_undergo_a">
              开始时间：{{ info.Date_ADT_Start }}
            </div>
            <div class="user_undergo user_undergo_a">
              结束时间：{{ info.Date_ADT_End }}
            </div>
            <div class="user_undergo user_undergo_a">
              审批天数：{{ info.ADT_Term }}
            </div>
            <div class="user_undergo user_undergo_a">
              延期天数：{{ info.Delay_Term }}
            </div>
            <div class="user_undergo user_undergo_a">
              备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注：{{ info.Remark }}
            </div>
            <div>
              <div class="user_undergo user_undergo_a">
                图片信息：
              </div>
              <img
                v-for="(item, i) in info.FileList"
                :key="i"
                :src="item.Url"
                style="width: 80px;height:80px;display: inline-block;padding: 2px"
                @click="picPreview"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="corporate_information">
        <div class="job_describe_top">
          <div class="jjxx">回复信息</div>
        </div>
        <div class="sj_job_box mt10"></div>
        <div class="job_describe_cengter2">
          <div class="user_undergo user_undergo_a">
            回复人员：{{ info.ReplyMan }}
          </div>
          <div class="user_undergo user_undergo_a">
            回复时间：{{ info.ReplyTime }}
          </div>
          <div class="user_undergo user_undergo_a">
            回复内容：{{ info.ReplyContent }}
          </div>
          <div class="user_undergo user_undergo_a">
            图片信息：
          </div>
          <img
            v-for="(item, i) in info.ReplyFileList"
            :key="i"
            :src="item.Url"
            style="width: 80px;height:80px;display: inline-block;padding: 2px"
            @click="replyPicPreview"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Base64 from '@/util/Base64'
  import { systemUrl } from '@/config'
  import { Toast } from 'vant'
  import { ImagePreview } from 'vant'
  import { getApproveInfo } from '@/api/workBench/approve/approve'
  import { Emas } from '@/assets/js/Emas'
  import store from '@/store'

  export default {
    name: 'ApproveInfo',
    components: {},
    data() {
      return {
        mstId: 0,
        systemUrl,
        info: {}, //详情数据
        fileList: [],
        replyFileList: [],
      }
    },
    created() {
      this.mstId =
        this.$route.query.mstId === undefined
          ? 0
          : Base64.decode(this.$route.query.mstId)
      // this.shopId = 4203
      this.fetchData()
      const userInfo = JSON.parse(store.getters['user/userInfo'])
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'approveInfo',
        '城管-行政审批详情',
        'zzdcg.yy.gov.cn/approveInfo'
      )
    },

    methods: {
      //获取店铺详情
      async fetchData() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        console.log(this.mstId)
        const { data } = await getApproveInfo({
          Mst_ID: this.mstId,
        })
        data.FileList = this.makeFileUrl(data.FileList)
        this.info = data
        Toast.clear()
      },

      //路径拼接
      makeFileUrl(fileList) {
        if (fileList.length > 0) {
          for (let i = 0; i < fileList.length; i++) {
            fileList[i].Url = systemUrl + fileList[i].Url
            switch (fileList[i].Type) {
              case 5:
                this.fileList.push(fileList[i].Url)
                break
              case 6:
                this.replyFileList.push(fileList[i].Url)
                break
            }
          }
        }
        return fileList
      },

      //照片预览
      picPreview() {
        ImagePreview({
          images: this.fileList,
        })
      },

      replyPicPreview() {
        ImagePreview({
          images: this.replyFileList,
        })
      },
      reply() {
        this.fetchData()
      },
    },
  }
</script>

<style scoped>
  .job_describe_cengter {
    padding: 0.4rem;
    border-bottom: 0.013333rem solid #eee;
  }
  .job_describe_cengter2 {
    padding: 0.4rem;
    border-bottom: 0.013333rem solid #eee;
  }

  .job_describe_cengter_header {
    font-size: 0.426667rem;
    font-weight: 700;
    color: #181818;
  }

  .job_describe_top {
    position: relative;
    width: 100%;
    padding: 0.273333rem 0.373333rem;
    font-size: 0.373333rem;
    background-color: #fff;
    border-bottom: 0.013333rem solid #eee;
  }
  .jjxx {
    position: relative;
    padding-left: 0.473333rem;
    font-size: 0.426667rem;
    font-weight: bold;
  }
  .jjxx::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background: url(../../../../assets/images/workBench/detail.png) no-repeat;
    background-size: auto;
    background-size: 100%;
  }
  .job_describe {
    width: 100%;
    margin-top: -1.333333rem;
    overflow: hidden;
    line-height: 1.8;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
  .min_body {
    padding-right: 0.32rem;
    padding-left: 0.32rem;
  }
  .jobshwheader {
    width: 100%;
    height: 2.026667rem;
    font-size: 0.32rem;
    background-color: #2778f8;
  }
  .user_undergo {
    width: 100%;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 0.8rem;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .user_undergo2 {
    width: 100%;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 2;
    color: #333;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }
  .user_undergo_box {
    padding: 0rem 0.4rem 0rem 0rem;
  }
  .user_undergo_a {
    position: relative;
  }
  .user_undergo_a::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    /*display: inline-block;*/
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background-size: auto;
    background-size: 100%;
  }
  .corporate_information {
    margin-top: 0.266667rem;
    overflow: hidden;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
</style>
